<template>
  <div class="homepage">
    <promotional-carousel />
    <shadow-background />
    <swiper-list
      v-if="latest.length > 0"
      title="Latest Videos"
      :items="latest"
    />
    <swiper-list
      v-if="latestSeries.length > 0"
      title="Latest Series"
      :items="latestSeries"
      type="series"
    />
    <author-list
      v-if="hottest_users.length > 0"
      title="Authors"
      :items="hottest_users"
    />
    <ranked-list
      v-if="most_liked.length > 0"
      title="Most liked Videos"
      :items="most_liked"
    />
    <ranked-list
      v-if="most_watched.length > 0"
      title="Most watched Videos"
      :items="most_watched"
    />
  </div>
</template>

<script>
import ShadowBackground from "@/components/home/carousel/ShadowBackground.vue";
import AuthorList from "@/components/home/AuthorList.vue";
import PromotionalCarousel from "@/components/home/carousel/PromotionalCarousel.vue";
import RankedList from "@/components/home/RankedList.vue";
import SwiperList from "@/components/home/SwiperList.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomeView",
  components: {
    AuthorList,
    PromotionalCarousel,
    RankedList,
    ShadowBackground,
    SwiperList,
  },
  mounted() {
    this.fetchLatest();
    this.fetchMostLiked();
    this.fetchMostWatched();
    this.fetchHottestUsers();
    this.fetchLatestSeries();
  },
  computed: {
    ...mapGetters("videos", [
      "latest",
      "most_liked",
      "most_watched",
      "hottest_users",
    ]),
    ...mapGetters("series", ["latestSeries"]),
  },
  methods: {
    ...mapActions("videos", [
      "fetchLatest",
      "fetchMostLiked",
      "fetchMostWatched",
      "fetchHottestUsers",
    ]),
    ...mapActions("series", ["fetchLatestSeries"]),
  },
};
</script>
