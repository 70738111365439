<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ modal_title }}</p>
      <span
        class="modal-card-head-icon icon-x"
        @click="$emit('closeModal')"
      ></span>
    </header>
    <section class="modal-card-body">
      <b-field label="Episode #">
        <b-input
          type="number"
          min="1"
          :max="maxEpisodeNumber"
          v-model="number"
          placeholder="Episode #"
          :disabled="!isEpisodeNumberEditable"
        >
        </b-input>
      </b-field>

      <b-field label="Video">
        <div class="modal-card-body__video">
          <b-select
            :placeholder="select_video_placeholder"
            v-model="videoid"
            required
            :disabled="id"
          >
            <option v-if="id && item" :value="videoid">{{ item.title }}</option>
            <option
              v-else
              v-for="video in available_for_episodes"
              :key="video.id"
              :value="video.id"
            >
              {{ video.title }}
            </option>
          </b-select>

          <span v-if="!id">or</span>
          <span
            class="modal-card-body__video-create"
            @click="openVideoModal()"
            v-if="!id"
            >Create a new video</span
          >
        </div>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: auto; margin-right: 0">
        <button-icon
          v-if="item"
          @click="confirmDeleteEpisode()"
          text="Delete episode"
          iconClass="icon-x"
          iconSide="right"
          cssBackgroundColor="#FFFFFF"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="black"
        />
        <button-icon
          :disabled="!this.number || !this.videoid"
          v-if="item"
          @click="update(episode)"
          text="Update episode"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
        <button-icon
          :disabled="!this.number || !this.videoid"
          v-if="!item"
          @click="create(episode)"
          text="Create episode"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "@/utils/utils";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import DOMPurify from "dompurify";

export default {
  name: "EpisodeModal",
  components: {
    ButtonIcon,
  },
  data() {
    return {
      id: null,
      number: null,
      videoid: null,
    };
  },
  async mounted() {
    if (this.item) {
      this.id = this.item.episode_id;
      this.number = Number(this.item.episode);
      this.videoid = this.item.id;
    } else {
      // fetch videos available to be added to an episode
      this.fetchAvailableForEpisodes(
        utils.isAdmin()
          ? this.impersonated_user
          : this.$keycloak.tokenParsed.preferred_username
      );

      this.number = this.maxEpisodeNumber + 1;
    }
  },
  computed: {
    ...mapGetters("modal", ["item"]),
    ...mapGetters("seasons", ["selected_season"]),
    ...mapGetters("videos", ["available_for_episodes"]),
    ...mapGetters("admin", ["impersonated_user"]),

    episode() {
      return {
        id: this.id || null,
        episode: Number(this.number),
        seasonid: this.selected_season.id,
        videoid: this.videoid,
      };
    },
    modal_title() {
      return this.item
        ? `Update Episode #${this.number}`
        : "Create New Episode";
    },
    maxEpisodeNumber() {
      return this.selected_season.episodesWithVideos.length;
    },
    isEpisodeNumberEditable() {
      return this.item && this.maxEpisodeNumber > 1;
    },
    select_video_placeholder() {
      return this.available_for_episodes &&
        this.available_for_episodes.length > 0
        ? "Select a video"
        : "No videos available (create a new video first)";
    },
  },
  methods: {
    ...mapActions("episodes", [
      "createEpisode",
      "updateEpisode",
      "deleteEpisode",
    ]),
    ...mapActions("modal", [
      "setOpen",
      "setItem",
      "setType",
      "setEpisodeNumber",
    ]),
    ...mapActions("videos", ["fetchAvailableForEpisodes"]),
    confirmDeleteEpisode() {
      const sanitizedEpisode = DOMPurify.sanitize(this.episode.episode);
      this.$buefy.dialog.confirm({
        title: "Deleting Episode",
        message: `Are you sure you want to <b>delete</b> the episode #${sanitizedEpisode}? </br>This action cannot be undone.`,
        confirmText: "Delete Episode",
        type: "is-danger",
        onConfirm: () =>
          this.deleteEpisode(this.episode.id).then(() =>
            this.$emit("closeModal")
          ),
      });
    },
    update() {
      if (!this.number || !this.videoid) return;
      this.updateEpisode(this.episode).then(() => this.$emit("closeModal"));
    },
    create() {
      if (!this.number || !this.videoid) return;
      this.createEpisode(this.episode).then(() => this.$emit("closeModal"));
    },
    openVideoModal() {
      this.setOpen(true);
      this.setType("video");
      this.setEpisodeNumber(this.number);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-body__video {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 32px;

  &-create {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
