<template>
  <div class="detail-serie">
    <div class="detail-serie__top">
      <button-icon
        text="Back"
        iconClass="icon-arrow_left"
        cssBackgroundColor="#ffffff"
        cssBorderRadius="12px"
        cssPadding="12px 16px"
        cssBorder="1px solid #E8EBED"
        @click="$router.push({ name: 'home' })"
      />
      <div class="detail-serie__top__details">
        <div class="detail-serie__top__details__left">
          <div class="detail-serie__top__details__left__title">
            {{ series.title }}
          </div>
          <div class="detail-serie__top__details__left__description">
            {{ series.description }}
          </div>
          <div
            class="detail-serie__top__details__left__categories"
            v-if="series && series.categories && series.categories.length > 0"
          >
            <tag-item
              v-for="tag in categories"
              :key="tag.id"
              :tag="tag"
              :isDeletable="false"
            />
          </div>
          <div
            class="detail-serie__top__details__left__info"
            v-if="series && series.owner"
          >
            <div class="detail-serie__top__details__left__info__author">
              <user-avatar :size="32" :username="series.owner?.username" />
              <span
                >by {{ series.owner?.name }} {{ series.owner?.surname }}</span
              >
            </div>
            <div class="detail-serie__top__details__left__info__date">
              <span class="detail-serie-icon icon-calendar"></span>
              <span>{{ series?.creation_date }}</span>
            </div>
          </div>
        </div>
        <div class="detail-serie__top__details__right">
          <cover-image
            v-if="series && series.image_url"
            :imageUrl="series.image_url"
            :isSerie="true"
          />
        </div>
      </div>
    </div>
    <div v-if="latestSeason">
      <swiper-list
        :key="latestSeason.id"
        title="Latest Season"
        :description="latestSeason.description"
        :items="latestSeason.episodesWithVideos"
        :is-boxed="true"
      />
    </div>
    <template v-for="season in series.seasonsWithVideos">
      <swiper-list
        :key="season.id"
        v-if="season.episodesWithVideos.length > 0"
        :title="`Season ${season.number}`"
        :description="season.description"
        :items="season.episodesWithVideos"
        :is-boxed="true"
        bg-color="#e8ebed"
      />
    </template>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import CoverImage from "@/components/detail/CoverImage.vue";
import UserAvatar from "@/components/shared/UserAvatar.vue";
import TagItem from "@/components/home/search/TagItem.vue";
import SwiperList from "@/components/home/SwiperList.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "DetailSerieView",
  components: {
    ButtonIcon,
    CoverImage,
    UserAvatar,
    TagItem,
    SwiperList,
  },
  mounted() {
    this.fetchSeriesById(this.$route.params.series_id);
  },
  computed: {
    ...mapGetters("series", ["series"]),
    categories() {
      return this.series.categories.map((cat) =>
        JSON.stringify({
          type: "categories",
          tag: cat.name,
          id: cat.id,
        })
      );
    },
    latestSeason() {
      var filtered = JSON.parse(JSON.stringify(this.series));
      filtered = filtered.seasonsWithVideos?.filter(
        (season) => season?.episodesWithVideos?.length > 0
      );
      if (filtered.length > 1) return filtered[filtered.length - 1];
      return null;
    },
  },
  methods: {
    ...mapActions("series", ["fetchSeriesById"]),
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.detail-serie {
  &-icon {
    // typography
    font-size: 1.5rem;
    color: #172126;
  }

  &__top {
    // position and layout
    flex-direction: column;
    row-gap: 1rem;

    // display and visibility
    display: flex;

    // box model
    padding: 175px 40px 40px 40px;

    &__details {
      // position and layout
      align-items: flex-start;
      justify-content: space-between;
      column-gap: 1rem;

      // display and visibility
      display: flex;

      &__left {
        // position and layout
        flex-direction: column;
        row-gap: 1rem;

        // display and visibility
        display: flex;

        // box model
        max-width: 35%;

        // typography
        color: black;

        &__title {
          // typography
          font-weight: 700;
          font-size: 3rem;
        }

        &__description {
          // typography
          font-size: 1.25rem;
          letter-spacing: 0.75px;
        }

        &__categories {
          // Display and Visibility
          display: flex;

          // Position and Layout
          flex-wrap: wrap;
          row-gap: 0.5rem;
        }

        &__info {
          // position and layout
          justify-content: space-between;

          // display and visibility
          display: flex;

          &__author {
            // position and layout
            column-gap: 11px;
            align-items: center;

            // display and visibility
            display: flex;

            // typography
            font-size: 0.75rem;
            letter-spacing: 0.75px;
          }

          &__date {
            // position and layout
            align-items: center;
            column-gap: 8px;

            // display and visibility
            display: flex;
          }
        }
      }
    }
  }
}

@include until-widescreen {
  .detail-serie {
    &__top {
      &__details {
        // position and layout
        flex-direction: column;

        &__left {
          // position and layout
          order: 2;
          row-gap: 1rem;

          // box model
          width: 100%;
          max-width: unset;

          &__title {
            // typography
            font-size: 2rem;
          }

          &__description {
            // typography
            font-size: 1rem;
          }
        }

        &__right {
          // position and layout
          order: 1;

          // box model
          width: 100%;
        }
      }
    }
  }
}
</style>
