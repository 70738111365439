<template>
  <div class="author">
    <div
      class="author-avatar"
      @click="
        () =>
          $router.push({ name: 'author', params: { username: user.username } })
      "
    >
      <user-avatar :size="200" :username="user.username" />
    </div>
    <div
      class="author-name"
      @click="
        () =>
          $router.push({ name: 'author', params: { username: user.username } })
      "
    >
      {{ user.name }} {{ user.surname }}
    </div>
    <div class="author-video">
      <span class="icon-film"></span>
      <span class="author-video-number" v-if="+user.total_videos > 1">
        {{ user.total_videos }} videos</span
      >
      <span class="author-video-number" v-else>
        {{ user.total_videos }} video</span
      >
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

export default {
  name: "AuthorItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserAvatar,
  },
};
</script>

<style lang="scss" scoped>
.author {
  // position and layout
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  // display and visibility
  display: flex;

  // box model
  width: 193.15px;
  padding: 0px;

  &-avatar {
    // background
    cursor: pointer;
  }

  &-name {
    // box model
    width: 209px;
    margin-top: 25px;

    // clipping
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // background
    cursor: pointer;

    // typography
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
  }

  &-video {
    // position and layout
    gap: 12px;

    // display and visibility
    display: flex;

    // box model
    height: 28px;

    // typography
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;

    color: #000000;
  }
}
</style>
